import React,{useState} from 'react';
//import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import ProfileD from './ProfileD';
import donald from '../images/donald.jpeg';
//import Team from '../Navbar/Team/Team';

const Donald = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

    <ProfileD
  image={donald}
  name="Donald Nweze"
  position="LLM (UK), BL, LLB, ICSAN, NIM"
  phone="+234 8063420644  "
  email="leadsattorneysandsolicitors@gmail.com"
  linkedIn="https://www.linkedin.com/in/donald-nweze-085082154/ "
  achievements={[
    `Donald is a Partner of the firm in the Oil & Gas, and commercial law practice unit of the firm.

    Donald earned a Bachelor of Laws Degree from the University of Nigeria in 2013 and was called to the Nigerian Bar in 2014. He also holds a Master of Law degree in International Business Law from the University of Salford, Manchester, United Kingdom.
    Donald started his legal career with the Law Firm of Ndoma-Egba (SAN), Ebri & Co, and subsequently joined a top law firm in Lagos, Nigeria where he amassed wealth of experience in dispute resolution, with dedicated interest in litigation and debt recovery.
    In 2017, Donald joined a leading oil and gas trading company in Lagos, Nigeria, where he handled complex transactions for international sale of petroleum products, regulatory compliance for oil and gas trading and maritime services in Nigeria as well as secretarial services and corporate governance, a role he played for 6 years before joining our firm.
    Donald is a Member of Nigerian Bar Association, Institute of Chartered Secretaries and Administrators of Nigeria and the Nigerian Institute of Management.
    `

  ]}
//   point1={[`Presidential election petition in Nigeria, 2019
//   `]}
//   point2={[`Oil spill litigation of one of the leading oil and gas company in Nigeria
//   `]}
//   point3={[`Tax compliances and legal opinion to a leading Nigerian telecommunication company.`]}
//   point4={[`Real estate dispute resolutions and litigations of one of the leading real estate companies in Nigeria.
//   `]}
//   point5={[`Representation of top financial institutions in Nigeria in different litigation dispute, ranging from mortgage, loan financing and real estate transactions financing.`]}
//   point6={[`Preparation of legal opinions on different areas of laws, such taxation, real estate transactions, loan facilities etc.
//   `]}
/>

     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Donald;
