import React,{useState} from 'react';
//import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import Profile from './Profile';
import david from '../images/david.jpeg';
//import Team from '../Navbar/Team/Team';

const David = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

    <Profile
  image={david}
  name="DAVID EDET"
  position="MBA ACIArb, BL, LLB  "
  phone="+2347031053365 "
  email="leadsattorneysandsolicitors@gmail.com"
  linkedIn="linkedin.com/in/david-edet "
  achievements={[
    `David Edet is Partner in the dispute resolution and international arbitration practice  unit of the firm, where he specializes in dispute resolution, particularly in energy, oil and gas, infrastructure, mining, construction, and joint venture. He also represents domestic and international clients in federal and state courts in Nigeria.
    David has acted as Tribunal Secretary in several high-volume institutional and ad-hoc arbitrations taking place in Africa. He also participates in international arbitration conferences as a panelist and moderator. In 2022, he was a Moderator during the World Cafe Discussions at the Vienna Arbitration Days and served as a member of the Planning Committee for the Silicon Valley Arbitration and Mediation Centre (SVAMC) Africa Technology and Arbitration Roundtable held in July 2022. David was also invited as a conference speaker to the 2022 Asia ADR Week organized by the Asian International Arbitration Centre (AIAC).
    
    David is currently a Facilitator at the Young ITA (Institute for Transnational Arbitration) Mentoring Program. He is an Associate member of the Chartered Institute of Arbitrators and an alumnus of the Winter Academy on International Arbitration.
     `

  ]}
  point1={[`Arbitration and dispute resolution

  `]}
  point2={[`Oil & gas

  `]}
  point3={[`Energy and natural resources
  `]}
  point4={[`Mining

  `]}
  point5={[`Construction and joint venture
  `]}
//   point6={[`Preparation of legal opinions on different areas of laws, such taxation, real estate transactions, loan facilities etc.
//   `]}
/>

     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default David;
