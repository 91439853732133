import React,{useState} from 'react';
//import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import Profile from './Profile';
import gerald from '../images/gerald.jpeg';
//import Team from '../Navbar/Team/Team';

const Gerald = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

    <Profile
  image={gerald}
  name="Chukwunonso Gerald Belonwu"
  position="LLM (UK), MSC, BSC, BL, LLB  "
  phone="+ 2348069799094, +63 927 967 4324 "
  email="leadsattorneysandsolicitors@gmail.com"
  linkedIn="https://www.linkedin.com/in/nonso-belonwu-70185485/ "
  achievements={[
    `C.G Belonwu is a partner of the firm in the property, criminal, development, and international law practice unit of the firm.
    He holds several degrees ranging from master’s degree in international law from the Queens Mary College, University of London, a master’s degree in development studies, a bachelor’s degree in political science and graduated from the University of Calabar where he obtained his law degree and subsequently BL.
    Before joining the firm, he has had over five years of law practice experience in one of the top tier law firms in Abuja, Nigeria and is firmly rooted in the implementation of international humanitarian law with respect to rights of persons deprived of liberty and refugees globally.
    `

  ]}
  point1={[`International Law


  `]}
  point2={[`International Humanitarian Law


  `]}
  point3={[`Criminal Law

  `]}
  point4={[`Property Law


  `]}
  point5={[`International Development Law.

  `]}
//   point6={[`Preparation of legal opinions on different areas of laws, such taxation, real estate transactions, loan facilities etc.
//   `]}
/>

     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Gerald;
