import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbars from './Components/Navbar/Navbars';
import Home from './Components/Home/Home';
import ScrollToTop from './Components/ScrollToTop';
//import travelImg from './Components/images/travelImg.jpeg';
import './App.css';
// import Button from 'react-bootstrap/Button';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
import { Modal, Form, Input,Button} from 'antd';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import slideImages from './Components/slideImages/slideImages';
import Team from './Components/Navbar/Team/Team';
import Footer from './Components/Footer/Footer';
import AboutUs from './Components/AboutUs/AboutUs';
import Services from './Components/Services/Services';
import Teams from './Components/Home/Teams';
import Dispute from './Components/Services/Dispute';
import Paul from './Components/Profile/Paul';
import Stanley from './Components/Profile/Stanley';
import Sotonye from './Components/Profile/Sotonye';
import Jessica from './Components/Profile/Jessica';
import Donald from './Components/Profile/Donald';
import Gerald from './Components/Profile/Gerald';
import David from './Components/Profile/David';

function App() {
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleOpenModal =()=> {
    console.log('hi')
    setModalVisible(true);
  }

  const handleCloseModal =()=> {
    setModalVisible(false);
  }

  const handleSubmit =(values) => {
    console.log(values);
    // you can add your form submission logic here
    handleCloseModal();
  }
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <BrowserRouter>
    <ScrollToTop>
          <div className='Appss'>
            {/* <Navbars /> */}

            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/Services' element={<Services />} />
              <Route exact path='/Teams' element={<Teams/>} />
              <Route exact path='/Dispute' element={<Dispute/>} />
              <Route exact path='/Paul' element={<Paul/>} />
              <Route exact path='/Stanley' element={<Stanley/>} />
              <Route exact path='/Sotonye' element={<Sotonye/>} />
              <Route exact path='/Jessica' element={<Jessica/>} />
              <Route exact path='/Donald' element={<Donald/>} />
              <Route exact path='/David' element={<David/>} />
              <Route exact path='/Gerald' element={<Gerald/>} />
            </Routes>
          </div>
          </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
