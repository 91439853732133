import React, { useState } from 'react';
import Navbars from '../../Components/Navbar/Navbars';
//import travelImg from './Components/images/travelImg.jpeg';
import '../../App.css';
// import Button from 'react-bootstrap/Button';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
import { Modal, Form, Input,Button} from 'antd';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import slideImages from '../../Components/slideImages/slideImages';
import Team from '../../Components/Navbar/Team/Team';
import Footer from '../../Components/Footer/Footer';
import AboutUs from '../../Components/AboutUs/AboutUs';
//import Services from './Components/Services/Services';
function Home() {
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleOpenModal =()=> {
    console.log('hi')
    setModalVisible(true);
  }

  const handleCloseModal =()=> {
    setModalVisible(false);
  }

  const handleSubmit =(values) => {
    console.log(values);
    // you can add your form submission logic here
    handleCloseModal();
  }
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <div className="Appss">
      <div className='tabNav'>
        
          <span className='tabInfo'> Email: paulikennab@gmail.com <span className="divider">|</span>  Phone:07036631933 
      </span>
        
          <Button className='formContact' onClick={handleOpenModal}>
        Contact Us
      </Button>
         
        
      </div>

      <Navbars/>
      
      <Modal
        title="Contact Us"
        open={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            name="firstName"
            label="First Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Phone Number"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true }]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
    <div className="slide-container">
      {/* <Fade>
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <img style={{ width: '100%',height:'500px' }} src={slideImage.url} />
            <p className='typewriter'>{slideImage.caption}</p>
            
          </div>
        ))}
      </Fade> */}
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={true}
        showStatus={false}
        showIndicators={true}
        showThumbs={false}
        interval={3000}
      >
        {slideImages.map((slideImage, index) => (
          <div key={index} className='slider'>
            <img src={slideImage.url} alt={slideImage.caption} />
            <p className='typewriter'>{slideImage.caption}<br/><br/><h2><b>Transforming legal services</b></h2></p>
          </div>
        ))}
      </Carousel>
    </div><br/><hr/>
    <AboutUs/><hr/>

    <div className="services-container">
      <div
        className={`service real-estate ${hoveredService === 'real-estate' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('real-estate')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaBalanceScale  size={100} className="service-icon" />
        <p>DISPUTE RESOLUTION,<br/> LITIGATION AND ARBITRATION</p>
      </div>
      <div
        className={`service visa-processing ${hoveredService === 'visa-processing' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('visa-processing')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaLandmark  size={100} className="service-icon" />
        <p>REAL ESTATE AND CONVEYANCING</p>
      </div>
      <div
        className={`service advertisement ${hoveredService === 'advertisement' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('advertisement')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaGavel  size={100} className="service-icon" />
        <p>CRIMINAL LITIGATION</p>
      </div>
      <div
        className={`service web-development ${hoveredService === 'web-development' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('web-development')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaFileInvoiceDollar size={100} className="service-icon" />
        <p>TAX LAWS</p>
      </div>
      <div
        className={`service blogging ${hoveredService === 'blogging' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('blogging')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaBriefcase  size={100} className="service-icon" />
        <p>CORPORATE, COMMERCIAL LAW, AND TRADE</p>
      </div>
      <div
        className={`service marketing ${hoveredService === 'marketing' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('marketing')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaFileSignature  size={100} className="service-icon" />
        <p>WILLS, TRUST & PROBATE</p>
      </div>
    </div><br/>
    <Team/><br/>
    <Footer/>
    
    </>
  );
}

export default Home;
