import React,{useState} from 'react';
//import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import Profile from './Profile';
import paul from '../images/paul.jpeg';
import stanley from '../images/stanley.jpeg';
//import Team from '../Navbar/Team/Team';

const Stanley = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

    <Profile
  image={stanley}
  name="Stanley Ugwu"
  position="LLM (UK), BL, LLB "
  phone="+447733676751, 08069181581 "
  email="leadsattorneysandsolicitors@gmail.com"
  linkedIn=""
  achievements={[
    `Stanley is a Partner of the firm in the arbitration, litigation, banking, and finance practice unit of the firm.
    Stanley holds a Master of Laws degree in international arbitration from the Prestigious University of Wolverhampton, United Kingdom, where he graduated with a distinction. 
   He is also a graduate of the University of Nigeria with a second-class upper division and been called to the Nigerian bar upon successful completion of the Nigerian law School. 
   Before he joined the firm, he had over five years of practical experience in one of the leading law firms in Yola, Adamawa state.
   
    `

  ]}
  point1={[`Criminal litigations involving high profile individuals.

  `]}
  point2={[`Civil litigation involving top financial institutions in Nigeria.

  `]}
  point3={[`Real estate transactions involving the purchase of large hectares of lands.
  `]}
  point4={[`ADR dispute resolutions involving commercial parties.

  `]}
  point5={[`Management of vast real estate properties in various cities in Nigeria. `]}
  point6={[`
  `]}
/>

     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Stanley;
