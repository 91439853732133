import React,{useState} from 'react';
import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import Team from '../Navbar/Team/Team';

const Teams = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

        <Team/>
     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Teams;
