import React,{useState} from 'react';
//import './Teams.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';
import Profile from './Profile';
import paul from '../images/paul.jpeg';
import ProfileI from './ProfileI';
//import Team from '../Navbar/Team/Team';

const Paul = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">

    <ProfileI
  image={paul}
  name="Paul Ikenna Eze"
  position="LLM (US), BL, LLB "
  phone="07036631933 "
  email="paulikennab@gmail.com"
  email2={"leadsattorneysandsolicitors@gmail.com"}
  linkedIn="linkedin.com/in/paul-ikenna-a84138160 "
  achievements={[
    `Paul Eze is a Principal Partner in the dispute resolution, litigation, and tax practice unit of the firm. 
    He holds a Master of Laws degree in Corporate commercial law and Trade, from the University of Illinois, Urbana Champaign, United States, and a certification on taxation. He has been called to Nigerian Bar.
    He graduated from the University of Nigeria with a second-class lower division and from the Nigerian Law School with a second-class upper division.
    Before joining the firm, he has over five years of law practice experience from a top tier law firm in Lagos Nigeria.    
    `

  ]}
  point1={[`Presidential election petition in Nigeria, 2019
  `]}
  point2={[`Oil spill litigation of one of the leading oil and gas company in Nigeria
  `]}
  point3={[`Tax compliances and legal opinion to a leading Nigerian telecommunication company.`]}
  point4={[`Real estate dispute resolutions and litigations of one of the leading real estate companies in Nigeria.
  `]}
  point5={[`Representation of top financial institutions in Nigeria in different litigation dispute, ranging from mortgage, loan financing and real estate transactions financing.`]}
  point6={[`Preparation of legal opinions on different areas of laws, such taxation, real estate transactions, loan facilities etc.
  `]}
/>

     <br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Paul;
