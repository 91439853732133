import React from 'react';
import './Team.css'
import donald from '../../images/donald.jpeg';
import sotonye from '../../images/sotonye.jpeg';
import paul from '../../images/paul.jpeg';
import gerald from '../../images/gerald.jpeg';
import jessica from '../../images/jessica.jpeg';
import david from '../../images/david.jpeg';
import stanley from '../../images/stanley.jpeg';
import { Link } from 'react-router-dom';

const Team = () => {
  return (
    <div className="team-container">
      <Link to={"/Paul"} className="team-member">
     
        <img src={paul} alt="Paul" className="team-member-image" />
        <h3 className="team-member-name">Paul Eze</h3>
        <p className="team-member-position">LLM (US), BL, LLB
</p>
     </Link>
      <Link  to ={"/Sotonye"}className="team-member">
        <img src={sotonye} alt="Sotonye" className="team-member-image" />
        <h3 className="team-member-name">Sotonye Belonwu</h3>
        <p className="team-member-position">LLM(US). BL, LLB</p>
      </Link>
      <Link to={"/Donald"} className="team-member">
        <img src={donald} alt="Donald" className="team-member-image" />
        <h3 className="team-member-name">Donald Nweze</h3>
        <p className="team-member-position">LLM (UK), BL, LLB, ICSAN, NIM</p>
      </Link>
      <Link to={"/Jessica"} className="team-member">
        <img src={jessica} alt="Jessica" className="team-member-image" />
        <h3 className="team-member-name">Jessica Utomi</h3>
        <p className="team-member-position">MSC (UK), BL, LLB</p>
      </Link>
      <Link to={"/Gerald"} className="team-member">
        <img src={gerald} alt="Gerald" className="team-member-image" />
        <h3 className="team-member-name">Gerald Belonwu</h3>
        <p className="team-member-position">LLM (UK), MSC, BSC, BL, LLB,</p>
      </Link>
      <Link to={"/David"} className="team-member">
        <img src={david} alt="David" className="team-member-image" />
        <h3 className="team-member-name">David Edet</h3>
        <p className="team-member-position">MBA ACIArb, BL, LLB
</p>
      </Link>
      <Link to={"/Stanley"} className="team-member">
        <img src={stanley} alt="Stanley" className="team-member-image" />
        <h3 className="team-member-name">Stanley Ugwu</h3>
        <p className="team-member-position">LLM (UK), BL, LLB</p>
      </Link>
    </div>
  );
};

export default Team;
