import React,{useState} from 'react';
import './Services.css';
import {  FaChartBar,FaBalanceScale,FaLandmark, FaGavel,FaFileInvoiceDollar, FaBriefcase,FaFileSignature } from 'react-icons/fa';
//import { Modal, Form, Input,Button} from 'antd';
// import service1 from './service1.png';
// import service2 from './service2.png';
// import service3 from './service3.png';
import travelImg from '../images/travelImg.jpeg';
import Navbars from '../Navbar/Navbars';
import Footer from '../Footer/Footer';

const Services = () => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleServiceHover = (service) => {
    setHoveredService(service);
  };
  return (
    <>
    <Navbars/>
    <section className="services-section">
      <div className='services-header'><h3>PRACTICE AREAS
</h3></div>
      <div className="services-container">
      <div
        className={`service real-estate ${hoveredService === 'real-estate' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('real-estate')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaBalanceScale  size={100} className="service-icon" />
        <p>DISPUTE RESOLUTION,<br/> LITIGATION AND ARBITRATION</p>
      </div>
      <div
        className={`service visa-processing ${hoveredService === 'visa-processing' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('visa-processing')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaLandmark  size={100} className="service-icon" />
        <p>REAL ESTATE AND CONVEYANCING</p>
      </div>
      <div
        className={`service advertisement ${hoveredService === 'advertisement' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('advertisement')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaGavel  size={100} className="service-icon" />
        <p>CRIMINAL LITIGATION</p>
      </div>
      <div
        className={`service web-development ${hoveredService === 'web-development' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('web-development')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaFileInvoiceDollar size={100} className="service-icon" />
        <p>TAX LAWS</p>
      </div>
      <div
        className={`service blogging ${hoveredService === 'blogging' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('blogging')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaBriefcase  size={100} className="service-icon" />
        <p>CORPORATE, COMMERCIAL LAW, AND TRADE</p>
      </div>
      <div
        className={`service marketing ${hoveredService === 'marketing' ? 'hovered' : ''}`}
        onMouseEnter={() => handleServiceHover('marketing')}
        onMouseLeave={() => handleServiceHover(null)}
      >
        <FaFileSignature  size={100} className="service-icon" />
        <p>WILLS, TRUST & PROBATE</p>
      </div>
    </div><br/>
    
    </section>
    <Footer/>
    </>
  );
};

export default Services;
