import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-title">
        <h2>About Us</h2>
      </div>
      <div className="about-us-content">
        <p>Leads Attorneys & Solicitors is a full-service law firm, with internationally based firm members who through their vast legal and practical knowledge provide cutting edge legal services to both local and international clients, in transactional, advisory, and dispute resolution services. We provide the very best legal solutions that helps our corporate and individual clients maximize their opportunities and achieve their business or personal goals.</p>
        <p>Clients’ satisfaction is our priority, therefore we endeavor to provide timely and excellent services, so we can build trust and a lasting relationship with all our clients. </p>
        <p>We provide legal services to clients across different industries, including real estate, taxation, banking, oil and gas, insurance, cross border transactions, corporate and commercial transactions, project financing, family planning, personal injury claims, and the list continues.</p>
        <p>Justice in law is our pursuit, and we do justice to every responsibility assigned to us by clients through excellent and timely service delivery on any assignment.</p>
      </div>
    </div>
  );
};

export default AboutUs;
