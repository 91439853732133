import React from 'react';
import arbitration from '../images/arbitration.jpeg';
import criminal from '../images/criminal.jpeg';
import realEstate from '../images/realEstate.jpeg';
const slideImages = [
    {
      url: arbitration,
      caption: 'JUSTICE IN LAW IS OUR PRIORITY'
    },
    {
      url: realEstate,
      caption: 'JUSTICE IN LAW IS OUR PRIORITY'
    },
    {
      url: criminal,
      caption: 'JUSTICE IN LAW IS OUR PRIORITY'
    },
  ];
  export default slideImages;