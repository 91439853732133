import React from 'react';
import './Footer.css';
import logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import { FaHome, FaSuitcase, FaBullhorn, FaCode, FaPen, FaChartBar } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="logo-container">
        <img src={logo} alt="Company logo" className='imgFooter' />
      </div>
      <div className="quick-links-container">
        <Link to="/">Home</Link>
        <Link to="/Services">Practice Area </Link>
        <Link to="/Teams">Legal Team</Link>
        <Link to="/Dispute">Dispute Resolution</Link>
        {/* <a href="#">CORPORATE, COMMERCIAL LAW</a>
        <a href="#">WILLS, TRUST & PROBATE</a> */}
      </div>
      <div className="address-container">
       
       <p> Address is NO.28 Gabriel Okoi Street <br /> Springbay Estate, Gbangbala<br /> Ikate Lagos<br/>Phone number: 07036631933<br/>Email: leadsattorneysandsolicitors@gmail.com</p>
      </div>
    </div>
  );
};

export default Footer;
